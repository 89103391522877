<template>
    <a-modal ref="modal" v-on-click-away="close" v-model="info.status"
             :footer="false" width="60vw">
        <div class="loan-detail-modal">
            <div class="loan-detail-modal__header">
                <p class="loan-detail-modal__heading font_montserrat_medium">{{ $t("components.common.loanInformation") }}</p>
            </div>
            <div class="loan-detail-modal__body">

                <LoanInfoRow
                    v-if="$parent.getLoanAccountById('1')"
                    title="components.common.mainLoanAccount"
                    :value="values.getAccountValueById('1')"
                    default-value="0"
                    @click="$parent.openAccountById('1')"
                />
                <LoanInfoRow
                    v-if="$parent.getAccountInfo('22618')"
                    title="components.common.accountForLoanInterestRepayment"
                    :value="values.getLoanValue('22618')"
                    default-value="0"
                    @click="$parent.openAccount('22618')"
                />
                <LoanInfoRow
                    v-if="$parent.getAccountInfo('29801')"
                    title="components.common.accountForRepaymentOfLoanInterest)"
                    :value="values.getLoanValue('29801')"
                    default-value="0"
                    @click="$parent.openAccount('29801')"
                />
                <LoanInfoRow
                    v-if="$parent.getAccountInfo('12505')"
                    title="components.common.overdueAccount"
                    :value="values.getLoanValue('12505')"
                    default-value="0"
                    @click="$parent.openAccount('12505')"
                />
                <LoanInfoRow
                    v-if="$parent.getLoanAccountById('3')"
                    title="components.common.interestAccount"
                    :value="values.getAccountValueById('3')"
                    default-value="0"
                    @click="$parent.openAccountById('3')"
                />
                <LoanInfoRow
                    v-if="$parent.getLoanAccountById('7')"
                    title="components.common.accountForAccrualOfInterestOnOverdueCourt"
                    :value="values.getAccountValueById('7')"
                    default-value="0"
                    @click="$parent.openAccountById('7')"
                />
                <LoanInfoRow
                    v-if="$parent.getAccountInfo('16377')"
                    title="components.common.accountOfAccruedButUnpaidInterestOnTime"
                    :value="values.getLoanValue('16377')"
                    default-value="0"
                    @click="$parent.openAccount('16377')"
                />
                <LoanInfoRow
                    v-if="$parent.getAccountInfo('16379')"
                    title="components.common.interestAccruedUnderTheAgreementButDeferred"
                    :value="values.getLoanValue('16379')"
                    default-value="0"
                    @click="$parent.openAccount('16379')"
                />
                <LoanInfoRow
                    title="components.common.total"
                    :value="getTotal()"
                />
            </div>
        </div>
    </a-modal>
</template>
<script>
import {directive as onClickAway} from "vue-clickaway";
import LoanInfoRow from "./partials/LoanInfoRow";

export default {
    name: 'LoanDetail',
    components: {LoanInfoRow},
    directives: {
        onClickAway
    },
    props: {
        info: {
            type: Object,
            required: true
        },
        values: Object,
    },
    methods: {
        close() {
            this.$emit('close')
        },
        getTotal() {
            const aAccounts = ['22618', '29801', '12505', '16377', '16379'];
            const aAccountsIds = ['1', '3', '7'];
            let iTotal = 0;
            aAccounts.forEach((item) => {
                const sAmount = this.values.getLoanValue(item) + '';
                iTotal += parseInt(sAmount.replace(/\s/g, ''));
            });
            aAccountsIds.forEach((item) => {
                const sAmount = this.values.getAccountValueById(item) + '';
                iTotal += parseInt(sAmount.replace(/\s/g, ''));
            });

            return this.$parent.formatSum(iTotal, false);
        }
    }
}
</script>
<style lang="scss">
.loan-detail-modal {
    &__header {
        padding: 32px 64px;
    }
    &__heading {
        margin: 0;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        color: var(--color-dark);
    }
    &__body {
        padding: 0 64px 32px;
    }
}
</style>
